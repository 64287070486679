import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import { Helmet } from 'react-helmet';

import Hero from 'components/Hero';
import Timeline from 'components/Timeline';
import PlusIcon from 'components/Icons/PlusIcon';
import Opening from 'components/Opening';
import useSiteMetadata from 'hooks/useSiteMetadata';
import VideoSection from 'components/VideoSection';

import css from './index.module.scss';
import shared from 'styles/shared.module.scss';

const Member = ({ member }) => {
  const memberImage = member.member_photoSharp;
  return (
    <div
      className={`${css.memberItem} ${
        member.primary_member === 'true' ? css.highlighted : ''
      }`}
    >
      {memberImage ? (
        <Img
          fluid={memberImage.childImageSharp.fluid}
          className={css.memberPhoto}
        />
      ) : null}
      <div className={css.memberInfo}>
        <h3 className={css.memberName}>
          {RichText.asText(member.member_name)}
        </h3>
        <h4 className={css.memberRole}>
          {RichText.asText(member.member_role)}
        </h4>
        {member.primary_member === 'true' && (
          <div className={css.memberDescription}>
            <RichText render={member.member_description} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ({ data: { prismic }, location }) => {
  const { siteUrl } = useSiteMetadata();

  const { company, allJob_openingss } = prismic;
  const membersHighlighted = company.members.filter(
    member => member.primary_member === 'true'
  );
  let members = company.members.filter(
    member => member.primary_member !== 'true'
  );
  const image = company.hero_imageSharp;
  const teamImage = company.team_imageSharp;

  const jobOpenings = allJob_openingss.edges || [];

  return (
    <>
      <Helmet>
        <title>{`SwissDeCode`}</title>
        <meta description={RichText.asText(company.hero_description)} />
        <meta property="og:title" content="SwissDeCode" />
        <meta
          property="og:description"
          content={RichText.asText(company.hero_description)}
        />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:image" content={company.hero_image.url} />
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        title={company.hero_title}
        description={company.hero_description}
      />
      <section className={`${css.about} ${shared.maxwidth}`}>
        <VideoSection
          label={company.about_subtitle}
          title={company.about_title}
          description={company.about_biography}
          video={{ url: 'https://www.youtube-nocookie.com/embed/sLTP_yxqU4U' }}
          videoClassName={css.aboutVideo}
        />
      </section>
      <section className={`${css.timeline} ${shared.maxwidth}`}>
        <Timeline years={company.timeline} />
      </section>
      <section className={`${css.valuesSection} ${shared.maxwidth}`}>
        <div className={css.valuesBkg} />
        <div className={css.valuesContainer}>
          {teamImage ? (
            <Img
              fluid={teamImage.childImageSharp.fluid}
              className={css.valuesImage}
              alt="Team"
            />
          ) : null}
          <div className={css.valuesContent}>
            <h4 className={shared.sectionType}>
              {RichText.asText(company.values_subtitle)}
            </h4>
            <h2 className={shared.sectionTitle}>
              {RichText.asText(company.values_title)}
            </h2>
            <ul className={css.valuesList}>
              {company.values.map((value, idx) => (
                <li key={`${idx + 1}`} className={css.valuesItem}>
                  <PlusIcon width={28} height={28} />
                  <div className={css.valuesInfo}>
                    <h4>{RichText.asText(value.value_title)}</h4>
                    <div>
                      <RichText render={value.value_description} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className={`${css.teamSection} ${shared.maxwidth}`}>
        <h4 className={shared.sectionType}>
          {RichText.asText(company.team_subtitle)}
        </h4>
        <h2 className={shared.sectionTitle}>
          {RichText.asText(company.team_title)}
        </h2>
        <div className={`${shared.sectionDescription} ${css.teamDescription}`}>
          <RichText render={company.team_description}></RichText>
        </div>
        <div className={css.mainMembers}>
          {membersHighlighted.map((member, idx) => (
            <Member key={`${idx + 1}`} member={member} />
          ))}
        </div>
        {members && members.length ? (
          <div className={css.teamMembers}>
            {members.map((member, idx) => (
              <Member key={`${idx + 1}`} member={member} />
            ))}
          </div>
        ) : null}
      </section>
      <section
        id="openings"
        className={`${css.hiringSection} ${shared.maxwidth}`}
      >
        <h4 className={shared.sectionType}>
          {RichText.asText(company.hiring_subtitle)}
        </h4>
        <h2 className={shared.sectionTitle}>
          {RichText.asText(company.hiring_title)}
        </h2>
        <div
          className={`${shared.sectionDescription} ${css.hiringDescription}`}
        >
          <RichText render={company.hiring_description} />
        </div>
        <div className={css.hiringContainer}>
          <div className={css.hiringTitle}>
            {RichText.asText(company.openings_title)}
          </div>
          {jobOpenings.length === 0 ? (
            <div className={css.hiringPlaceholder}>
              <RichText render={company.no_opening_text} />
            </div>
          ) : null}
          {jobOpenings.length > 0 ? (
            <ul className={css.hiringList}>
              {jobOpenings.map((opening, idx) => (
                <li key={idx}>
                  <Opening
                    opening={opening.node}
                    buttonLabel={company.opening_button_label || ''}
                  />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </section>
    </>
  );
};

export const pageQuery = graphql`
  query companyPage {
    prismic {
      company(uid: "company", lang: "pt-pt") {
        _meta {
          id
        }
        hero_description
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        hero_title
        about_title
        about_subtitle
        about_biography
        timeline {
          year
          year_description
        }
        values {
          value_description
          value_title
        }
        values_subtitle
        values_title
        team_image
        team_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        team_description
        team_subtitle
        team_title
        opening_label
        members {
          primary_member
          member_description
          member_name
          member_role
          member_photo
          member_photoSharp {
            childImageSharp {
              fluid(quality: 90, maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hiring_title
        hiring_subtitle
        hiring_description
        openings_title
        no_opening_text
        opening_button_label
      }
      allJob_openingss {
        edges {
          node {
            hero_title
            summary_fields {
              field_label
              field_value
            }
            _meta {
              uid
              type
            }
          }
        }
      }
    }
  }
`;
