import React, { useState, useEffect, useCallback, useRef } from 'react';
import { RichText } from 'prismic-reactjs';

import css from './timeline.module.scss';

const Timeline = ({ years }) => {
  const points = years || [];
  const [activeYear, setActiveYear] = useState(0);
  const width = (activeYear / (points.length - 1)) * 100;
  const interval = useRef();
  const yearRef = useRef(0);
  const widthRef = useRef();

  const setManually = useCallback(idx => {
    clearInterval(interval.current);
    yearRef.current = idx;
    setActiveYear(idx);
  }, []);

  const activateAnim = useCallback(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      yearRef.current = yearRef.current + 1;
      if (yearRef.current > points.length - 1) {
        yearRef.current = 0;
      }
      setActiveYear(yearRef.current);
    }, 5000);
  }, [points.length]);

  const onResize = useCallback(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 600 && widthRef.current > 600) {
      clearInterval(interval.current);
    } else if (windowWidth > 600 && widthRef.current <= 600) {
      activateAnim();
    }
    widthRef.current = windowWidth;
  }, [activateAnim]);

  useEffect(() => {
    widthRef.current = window.innerWidth;
    if (widthRef.current > 600) {
      activateAnim();
    }

    window.addEventListener('resize', onResize);

    return () => {
      clearInterval(interval.current);
      window.removeEventListener('resize', onResize);
    };
  }, [activateAnim, onResize]);

  return (
    <div
      className={css.yearList}
      onMouseLeave={activateAnim}
      role="presentation"
    >
      <div className={css.yearLine}>
        <div
          className={css.yearLineFill}
          style={{
            width: `${width}%`,
          }}
        />
      </div>
      {points.map((point, idx) => (
        <div
          key={`${idx + 1}`}
          role="presentation"
          className={`${css.yearItem} ${activeYear === idx ? css.active : ''} ${
            activeYear >= idx ? css.selected : ''
          } ${idx === 0 ? css.first : ''} ${
            idx === points.length - 1 ? css.last : ''
          }`}
          onMouseEnter={() => setManually(idx)}
        >
          <div className={css.year}>{point.year}</div>
          <div className={css.tooltip}>
            <RichText render={point.year_description} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
