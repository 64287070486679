import React from 'react';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Button from '../Button';

import css from './opening.module.scss';

const Opening = ({ opening, buttonLabel }) => {
  return (
    <div className={css.host}>
      <div className={css.info}>
        <div className={css.title}>{RichText.asText(opening.hero_title)}</div>
        {opening.summary_fields.map((field, idx) => (
          <div key={idx} className={css.item}>
            <strong>{field.field_label}</strong>
            <span>{field.field_value}</span>
          </div>
        ))}
      </div>
      <Link to={`/jobs/${opening._meta.uid}`}>
        <Button filled className={css.detailBtn}>
          {buttonLabel}
        </Button>
      </Link>
    </div>
  );
};

export default Opening;
